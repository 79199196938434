/**
 *  Date    : 2019/11/7
 *  Author  : Weilin
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import * as Cookie from '@/utils/cookie';
import { message } from '@/components/AntD';
import { LOCAL_STORAGE_KEYS } from '@/constants/commonKeys';
import demandapi from '@/api/demandapi';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    updateField: (field, data) => {
      return {
        type: 'user/update',
        field: field,
        data
      };
    },
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/update',
        field: 'userInfo',
        promise: demandapi.getUserInfo()
      };
    },
    logout() {
      return {
        promise: new Promise(() => {
          // 直接退出系统
          Cookie.saveCookie('');  // 清除token
          localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, '');
          sessionStorage.removeItem('cbPath');
          message.success('退出成功');
          window.location.href = '/login';
        })
      };
    }
  }
};
