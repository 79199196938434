// 对话用到的redux，无关的别丢里面
import Immutable from 'seamless-immutable';

export default {
  namespace: 'dialogue',
  state: Immutable({
    bookInfo: {}, // 书籍详情
    digitalPersonInfo: {}, // 当前数字人详情
    digitalPersonDataInfo: {}, // 当前选中数字人详情
    digitalPersonList: [], // 数字人员工列表
    historyState: {},
    routerQuery: {}, //windo.open 的时候存储路由参数用
    allDigitalPersonList: [], //
    previewProps: { // 图片预览
      visible: false,
      images: [],
      defaultIndex: 0
    },
    msgUpdateTimes: 0,
    listProceedReviserUpdateTimes: 0,
    videoProps: { visible: false, src: '' },
    evaluateProps: { visible: false },
    moresProps: { visible: false },
    modalVisible: false, // 弹框类事件显示 MODAL_TYPE
    msgDelOnReply: {
      timestamp: null,
      requestId: null,
      delCallback: null
    }, //收到回复就要删除的消息
    bookServiceVisible: false,
    msgHeightDeltas: {},
    serveListFirstPage: null, //书的服务列表第一页
    touching: false,
    openMore: false, //editor的openmore状态
    showHoverAudio: false,
    hoverAudioInfo: null,
    showExpire: false,
    expireInfo: {}
  }),
  reducers: {
    // 通用update
    update(state, { data, field }) {
      return state.merge({ [field]: data });
    },
    setFieldValues(state, { data, field }) {
      const values = Object.assign({}, state[field], data);
      return state.merge({ [field]: values });
    },
    updateResourceViewer(state) {
      return state.merge({ ...defaultResourceViewerProps });
    }
  },
  actions: {
    updateField: (field, data) => {
      return {
        type: 'dialogue/update',
        field,
        data
      };
    },
    updateFieldValues: (field, data) => {
      return {
        type: 'dialogue/setFieldValues',
        field,
        data
      };
    },
    resetResourceViewerProps: () => {
      return {
        type: 'dialogue/updateResourceViewer'
      };
    },
    getLastestMsg: () => {
      return {
        type: 'dialogue/update/msgUpdateTimes',
        field: 'msgUpdateTimes',
        data: new Date().getTime()
      };
    },
    getListProceedReviserUpdateTimes: (data = -(new Date().getTime())) => {
      return {
        type: 'dialogue/update/listProceedReviserUpdateTimes',
        field: 'listProceedReviserUpdateTimes',
        data
      };
    }
  }
};

const defaultResourceViewerProps = {
  previewProps: { // 图片预览
    visible: false,
    images: [],
    defaultIndex: 0
  },
  videoProps: { // 视频预览
    visible: false,
    src: ''
  },
  evaluateProps: { // 使用反馈
    visible: false
  },
  moresProps: {
    visible: false
  },
  modalVisible: false // 弹框类事件显示 MODAL_TYPE
};
