import { LOCAL_STORAGE_KEYS } from '@/constants/commonKeys';

// 登录页不做处理
const pathnames = [
  '/login',
  '/invitation'
];

export function tokenChangeListener() {
  window.addEventListener('storage', (event) => {
    if(event.type === 'storage' && event.key === LOCAL_STORAGE_KEYS.TOKEN) {
      const { oldValue, newValue } = event;
      if(newValue && newValue !== oldValue && !pathnames.some(p => window.location.pathname.startsWith(p))) {
        window.location.reload();
      }
    }
  });
}
