import user from './user';
import projectSetting from './project-setting';
import common from './common';
import studio from './studio';
import dialogue from './dialogue';

export default [
  user,
  common,
  projectSetting,
  studio,
  dialogue
];
