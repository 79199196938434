import loadable from '@loadable/component';

import { createRouter } from '@/utils/router';
import PAGE from '@/constants/page';
import Loading from '@/components/Loading';
import { tokenChangeListener } from '@/utils/storageListener';

import App from './App';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    tokenChangeListener();
    if(location.pathname === '/' || location.pathname === '/channel') {
      history.replace(PAGE.HOME);
    }
    cb();
  },
  getChildRoutes: () => [
    {
      path: '/login',
      isExact: true,
      component: loadable(() => import('./login'), { fallback: Loading })
    },
    {
      path: '/channel',
      component: loadable(() => import('./channel'), { fallback: Loading })
    },
    {
      path: '/testcomponents',
      component: loadable(() => import('./testcomponents'), { fallback: Loading })
    },
    {
      component: loadable(() => import('./404'), { fallback: Loading })
    }
  ]
});
