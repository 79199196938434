import React from 'react';
import { Switch } from 'react-router-dom';

import { ConfigProvider } from '@/components/AntD';
import uuid from '@/utils/uuid';

import zhCN from 'antd/lib/locale/zh_CN';
// 先引入antd样式，再引项目样式
import 'antd/lib/style';

import './App.scss';

export default class App extends React.PureComponent {

  componentDidMount() {
    const ismac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(ismac) {
      document.body.setAttribute('ismac', '1');
    }
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    return (
      <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false}>
        <Switch>
          {this.props.children}
        </Switch>
      </ConfigProvider>
    );
  }
}

if(!window.shortid) {
  window.shortid = uuid;
}
