import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import config from '@/config';

// config.env !== 'TEST' && Sentry.init({
//   dsn: 'https://86702ad46dac6a9b65463a5cd4e6aac3@sentry.raysyun.com/13',

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   // integrations: [new Sentry.Replay()],

//   integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   maxBreadcrumbs: 5,
//   env: config.env,
//   beforeSend(event) {
//     // 被捕获事件，不上报
//     // 音视频无法播放，不上报
//     if(event.message && (event.message.includes(CAPTURED) || event.message.toUpperCase().includes('ALLOW'))) {
//       return null;
//     }
//     // 被捕获事件，不上报
//     if(event.exception && event.exception.values) {
//       if(event.exception.values instanceof Array) {
//         if(event.exception.values.some(v => v.value && String(v.value).includes(CAPTURED))) {
//           return null;
//         }
//       }
//     }
//     return event;
//   }
// });

export const CAPTURED = '错误';

export default Sentry;
