import Immutable from 'seamless-immutable';

const INIT_BREAD = {
  name: '',
  path: [],
  action: ''
};

export default {
  namespace: 'common',
  state: Immutable({
    showBreadcrumb: true,
    dynamicList: [],
    breadCrumbs: { ...INIT_BREAD },
    /** 手动传入的面包屑 */
    manualBreadcrumbs: [],
    manualBreadcrumbsClassName: '',
    context: {},
    aiCostRule: [],
    uploadConfig: {},
    /** 后台任务 */
    newDownloadTask: null
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    addRoute: (state, { data }) => {
      let dynamicArr = [];
      if(Array.isArray(data)) {
        dynamicArr = data;
      }
      if(data.constructor === Object && 'path' in data) {
        if(!state.dynamicList.some(v => v.path === data.path)) {
          dynamicArr = [...state.dynamicList, data];
        }
      }
      return state.merge({
        dynamicList: dynamicArr
      });
    },
    removeRoute: (state, { data }) => {
      let dynamicArr = [];
      if(data.constructor === Object && 'path' in data) {
        dynamicArr = state.dynamicList.filter(v => v.path !== data.path);
      }
      return state.merge({
        dynamicList: dynamicArr
      });
    },
    addBreadCrumb: (state, { data }) => {
      let breadcrumbValues = { ...INIT_BREAD };
      if(typeof data === 'string') {
        breadcrumbValues.path = [data];
      }
      if(data.path) {
        if(!Array.isArray(data.path)) {
          return console.error(`path must be Array`);
        }
        breadcrumbValues = data;
      }
      return state.merge({
        breadCrumbs: breadcrumbValues
      });
    },
    removeBreadCrumb: (state) => {
      return state.merge({
        breadCrumbs: { ...INIT_BREAD }
      });
    },
    removeDownloadTask: state => {
      return state.merge({
        newDownloadTask: null
      });
    }
  },
  actions: {
    addRoute: function(router) {
      return {
        type: 'common/addRoute',
        data: router
      };
    },
    removeRoute: function(router) {
      return {
        type: 'common/removeRoute',
        data: router
      };
    },
    setShowBreadcrumb: function(flag) {
      return {
        type: 'common/update',
        data: flag,
        field: 'showBreadcrumb'
      };
    },
    addBreadCrumb: function(breadcrumb) {
      return {
        type: 'common/addBreadCrumb',
        data: breadcrumb
      };
    },
    removeBreadCrumb: function(breadcrumb) {
      return {
        type: 'common/removeBreadCrumb',
        data: breadcrumb
      };
    },
    setSearchOperation(key, searchOptions) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: searchOptions
      };
    },
    resetSearchOperations(key) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: {}
      };
    },
    setContext: function(key, context) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: context
      };
    },
    addDownloadTask(data) {
      const field = 'newDownloadTask';
      return {
        type: `common/update/${field}`,
        field,
        data
      };
    },
    removeDownloadTask() {
      return {
        type: 'common/removeDownloadTask'
      };
    },
    setManualBreadcrumb(data) {
      return {
        type: 'common/update',
        field: 'manualBreadcrumbs',
        data
      };
    },
    setManualBreadcrumbsClassName(data) {
      return {
        type: 'common/update',
        field: 'manualBreadcrumbsClassName',
        data
      };
    }
  }
};
