
import Immutable from 'seamless-immutable';

export default {
  namespace: 'studio',
  state: Immutable({
    selectImgSizeOption: [],
    selectImgStyleOption1: [],
    selectImgStyleOption2: [],
    selectCallWordOption: [],
    aiPainterForm: {},
    activeStyleKey1: 0,
    activeStyleKey2: 0,
    authorMaterialUploading: false,
    myStyleList: []
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    updateField: (field, data) => {
      return {
        type: 'studio/update',
        field: field,
        data
      };
    }
  }
};
