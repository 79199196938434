import Cookies from 'universal-cookie';

import config from '@/config';

const { COOKIE } = config;

const TEN_YEAR = 7 * 24 * 60 * 60 * 1000;

const defaultConfig = {
  path: '/',
  maxAge: TEN_YEAR
};

const cookies = new Cookies();

export function saveCookie(value, name = COOKIE, cookiePath = defaultConfig) {
  // TODO: change aistudio
  if(name === COOKIE) cookiePath = { ...cookiePath, domain: (location.host.match('aistudio.') ? location.host : undefined) };
  return cookies.set(name, value, cookiePath);
}

export function getCookie(name = COOKIE, cookiePath) {
  return cookies.get(name, cookiePath);
}

export function removeCookie(name = COOKIE, domain) {
  // TODO: change aistudio
  if(name === COOKIE) domain = (location.host.match('aistudio.') ? location.host : undefined);
  return cookies.remove(name, { domain });
}
